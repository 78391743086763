import React from "react";
// import { LastLocationProvider } from "react-router-last-location";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import Landing from "./pages/customer/Landing";

function App() {
  return (
    <>
      <AuthProvider>
        <Router>
          <Route exact path="/" component={Landing} />
          {/* <Route
            exact
            path="/registerPartner"
            component={RegisterPartnerService}
          />
          <Route
            exact
            path="/partnerServiceRegistered/:partnerServiceId"
            component={PartnerServiceRegistered}
          />

          <Route exact path="/orders" component={Orders} />
          <Route exact path="/createOrder" component={CreateOrder} />
          <Route exact path="/checkoutOrder" component={CheckoutOrder} />
          <Route exact path="/trackOrder/:orderId" component={OrderReceived} />

          <AuthRoute
            exact
            path="/signup/:referralCode?"
            component={RegisterCustomer}
          />
          <AuthRoute exact path="/login" component={LoginCustomer} /> */}
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
