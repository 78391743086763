import React, { useEffect } from "react";
// import { AuthContext } from "../../context/auth";

export default function Landing(props) {
  useEffect(() => {
    document.title = "Softwair.shop";
  }, []);
  // const { customer } = useContext(AuthContext);

  return (
    <div className="flex space-y-2 flex-col items-center justify-center h-full w-full min-h-screen max-h-screen bg-black text-white">
      <p className="uppercase font-semibold text-xl w-min lg:text-2xl">
        Softwair<span className="text-sm">.shop</span>
      </p>

      <p>
        This project failed! All data stored by Softwair.shop has been safely
        deleted.
      </p>
      <p className="font-bold">Thank you for your time!</p>
    </div>
  );
}
